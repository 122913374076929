var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "parking-facility-list-component table-component" },
    [
      _c("div", { staticClass: "page-header" }, [
        _c(
          "div",
          { staticClass: "page-breadcrumb" },
          [
            _c(
              "a-breadcrumb",
              [
                _c(
                  "a-breadcrumb-item",
                  [
                    _c("router-link", { attrs: { to: _vm.pagePath } }, [
                      _vm._v(_vm._s(_vm.pageTitle)),
                    ]),
                  ],
                  1
                ),
                _c("a-breadcrumb-item", [_vm._v("设备管理")]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "page-body page-body-margin" },
        [
          _c(
            "a-layout",
            [
              _c(
                "a-layout-sider",
                { attrs: { theme: "light" } },
                [
                  _c(
                    "a-card",
                    { attrs: { title: "物理空间", bordered: false } },
                    [
                      _vm.getLocationTreeService
                        ? _c("jtl-tree-space-component", {
                            attrs: {
                              "space-id": _vm.LocationId,
                              "get-location-tree-service":
                                _vm.getLocationTreeService,
                              "get-location-tree-service-param":
                                _vm.SubSystemName,
                            },
                            on: { select: _vm.treeSelect },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("a-layout-content", [
                _c(
                  "div",
                  { staticClass: "page-body-margin-left-16" },
                  [
                    _c(
                      "a-card",
                      {
                        staticClass: "table-query-card",
                        attrs: { title: "设备查询" },
                      },
                      [
                        _c("jtl-table-query-component", {
                          ref: _vm.JTL.CONSTANT.DEFAULT_TABLE_QUERY_NAME,
                        }),
                      ],
                      1
                    ),
                    _c(
                      "a-card",
                      { attrs: { title: "设备列表" } },
                      [
                        _c(
                          "jtl-button",
                          {
                            staticClass: "table-header-button",
                            attrs: { slot: "extra", type: "primary" },
                            on: { click: _vm.getList },
                            slot: "extra",
                          },
                          [_vm._v("刷新")]
                        ),
                        _c("a-table", {
                          attrs: {
                            loading: _vm.listLoading,
                            columns: _vm.tableColumns,
                            "data-source": _vm.listData,
                            pagination: _vm.Pagination,
                            scroll: _vm.tableScroll,
                            "row-key": _vm.TableRowKey,
                            size: _vm.TableSize,
                          },
                          on: { change: _vm.tableChange },
                          scopedSlots: _vm._u([
                            {
                              key: "name",
                              fn: function (text, record) {
                                return [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "jtl-link",
                                      on: {
                                        click: function ($event) {
                                          return _vm.detailClick(record)
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(text))]
                                  ),
                                ]
                              },
                            },
                            {
                              key: "attribute",
                              fn: function (text, record) {
                                return [
                                  _c(
                                    "a-row",
                                    { attrs: { type: "flex", gutter: [8, 8] } },
                                    _vm._l(
                                      record.attrList,
                                      function (item, index) {
                                        return _c(
                                          "a-col",
                                          { key: index },
                                          [
                                            _c("a-tag", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(item.name) +
                                                  ": " +
                                                  _vm._s(item.value) +
                                                  _vm._s(item.unit) +
                                                  " "
                                              ),
                                            ]),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ]
                              },
                            },
                            {
                              key: "action",
                              fn: function (text, record) {
                                return [
                                  _c(
                                    "a",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.detailClick(record)
                                        },
                                      },
                                    },
                                    [_vm._v("查看")]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("parking-facility-detail-drawer", {
        ref: "parkingFacilityDetailDrawer",
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }