



















































































import { Component, Mixins } from 'vue-property-decorator';
import TableComponent from '@common-src/mixins/table-component';
import SubSystemComponent from '@common-src/mixins/sub-system-component';
import { ParkingFacilityQueryModel } from './model/parking-facility-entity';
import ParkingFacilityService from './service/parking-facility';
import ParkingFacilityDetailDrawer from './parking-facility-detail-drawer.vue';
import { SubSystemType } from '@common-src/model/enum';
import { entityStatusFilter } from '@common-src/filter';

const TableColumns = [
    {
        title: '设备名称',
        dataIndex: 'name',
        width: 200,
        scopedSlots: { customRender: 'name' }
    },
    {
        title: '设备类型',
        width: 150,
        dataIndex: 'typeName'
    },
    {
        title: '安装位置',
        width: 150,
        dataIndex: 'locationName'
    },
    {
        title: '数值',
        scopedSlots: { customRender: 'attribute' }
    },
    {
        title: '启用状态',
        width: 100,
        customRender: (text, record, index) => {
            return entityStatusFilter(record.enabled);
        }
    },
    {
        title: '操作',
        width: 80,
        scopedSlots: { customRender: 'action' }
    }
];

@Component({
    components: {
        'parking-facility-detail-drawer': ParkingFacilityDetailDrawer
    }
})
export default class ParkingFacilityListComponent extends Mixins(TableComponent, SubSystemComponent) {
    getLocationTreeService: Function = null;
    pageTitle: string = null;
    pagePath: string = '/';

    initPage() {
        this.getLocationTreeService = null;
        this.$nextTick(() => {
            this.getLocationTreeService = ParkingFacilityService.getLocationTree;
            switch (this.SubSystemName) {
                case SubSystemType.ALL:
                    this.pageTitle = '首页';
                    break;
                case SubSystemType.PARKING:
                    this.pageTitle = '停车场管理';
                    this.pagePath = '/dashboard/parking-index';
                    break;
                case SubSystemType.FIRE:
                    this.pageTitle = '消防管理';
                    this.pagePath = '/dashboard/fire-index';
                    break;
                case SubSystemType.CAMERA:
                    this.pageTitle = '监控管理';
                    this.pagePath = '/dashboard/camera-index';
                    break;
                case SubSystemType.ENVIROMENT:
                    this.pageTitle = '环境管理';
                    this.pagePath = '/dashboard/env-index';
                    break;
                case SubSystemType.ENERGY:
                    this.pageTitle = '能源管理';
                    this.pagePath = '/dashboard/energy-index';
                    break;
                case SubSystemType.BA:
                    this.pageTitle = '楼宇自控系统';
                    this.pagePath = '/dashboard/ba-index';
                    break;
                case SubSystemType.CUSTOMER_FLOW:
                    this.pageTitle = '客流系统';
                    this.pagePath = '/dashboard/customer-flow-index';
                    break;
                case SubSystemType.ELEVATOR:
                    this.pageTitle = '电梯系统';
                    this.pagePath = '/dashboard/elevator-index';
                    break;
                case SubSystemType.LIGHT:
                    this.pageTitle = '照明管理';
                    this.pagePath = '/dashboard/light-index';
                    break;
                case SubSystemType.ACCESS_CONTROL:
                    this.pageTitle = '门禁管理';
                    this.pagePath = '/dashboard/access-control-index';
                    break;
                case SubSystemType.COMPUTER_ROOM:
                    this.pageTitle = '机房动环管理';
                    this.pagePath = '/dashboard/computer-room-index';
                    break;
            }
            const typeIdentifiers = this.$route.query?.typeIdentifiers as string;
            this.initTable({
                listFunc: ParkingFacilityService.query,
                queryModel: new ParkingFacilityQueryModel(this.SubSystemName, typeIdentifiers ? [typeIdentifiers] : null),
                tableColumns: TableColumns
            });
        });
    }

    get LocationId() {
        return this.$route.query.locationId as string;
    }

    treeSelect(locationIds: Array<string>) {
        const locationId = _.first(locationIds);
        (this.queryModel as ParkingFacilityQueryModel).locationIds = locationIds;
        this.$router.push(`${this.$route.path}?locationId=${locationId}`);
    }

    detailClick(model: any) {
        (this.$refs.parkingFacilityDetailDrawer as ParkingFacilityDetailDrawer).drawerDetailOpen(model.id, model.name, this.SubSystemName);
    }
}

